/* 
* SELECTORS
*/
const getLinkableTableRows = () => $("tr[data-link]");


/* 
* FUNCTION DEFINITIONS
*/

// Instead of using javascript on table rows, lets wrap each element in an <a> tag unless it is selectable
$.fn.addLinksToRowCells = function(){
	this.each(function(index, row) {
		const link = $(row).data('link');
		$(row).find("td:not(.unlink)").each( (index, cell) => {
			$(cell).css({position: "relative"})
				.wrapInner('<a href="' + link + '" class="anchor-block-overlay"><div></div></a>');
		});
	});
}

// $(document).ready(function() {
document.addEventListener("turbolinks:load", function() {

	// Initialize selected elements
	$('[data-toggle="tooltip"]').tooltip()
	$('[data-toggle="popover"]').popover({
		html: true
	});

	// Make any <tr> element with data-link go to that link on click
	getLinkableTableRows().addLinksToRowCells();
	// $("tr[data-link] td:not(.unlink)").on("click", function() { window.location = $(this).parent().data("link") });

	function formatFromTitle (option) {
		// console.log(option.title)
		if (!option.title) {
			return option.text;
		}
		return $(option.title);
	}

	// This class should apply title formatting to items in the select2 inputs in this element
	$(".select-titleable").each(function(){
		$(this).select2({
			theme: "bootstrap4",
			templateResult: formatFromTitle
		});
	});

	// this should make form elements selectable. When clicked, they will check/select the first input within them,
	// unless that element has an unlink class.
	$("tr.selectable_input_row td:not(.unlink)").on("click", function(){
		// this could be any td that was clicked, so lets go up to the row, then find the first input
		$(this).parent().find('input').prop("checked", true).change();
	});

	////// BOOTSTRAP COLOR PICKER INIT
	// Basic instantiation:
	// $('.colorpicker').colorpicker({
	// 	format: "rgba",
	// 	useAlpha: true,
	// 	align: "left"
	// });
	// Example using an event, to change the color of the .jumbotron background:
	// $('.colorpicker').on('colorpickerChange', function(event) {
	// 	$('.jumbotron').css('background-color', event.color.toString());
	// });

	$('.disable-on-submit').on('submit',function() {
		$(this).addClass('disabled');
	});

	$('.disable-on-click').on('click',function() {
		$(this).addClass('disabled');
	});

	// replaces the contents of a div with the local time verson based on data-time
	// <span data-time="{{some_date}}">{{some_date}}</span>
	$("[data-time]").each(function() {
		var timestamp = $(this).data("time");
		var localeTimestamp = new Date(timestamp).toLocaleString([], {"dateStyle": "medium", "timeStyle": "medium"});
		$(this).html(localeTimestamp.replace(/:\d{2}\s/,' '));
	});
});
